import * as React from "react";
import {
    logo,
    banner,
    bannerText,
    divider,
    dividerWhite,
    dividerWhiteMini,
    gridText,
    grid,
    products,
    header,
    ypo,
    ybo,
    yto,
    circle,
    partners,
    testimonials,
    testimony,
    partnerHeader,
    productLineUp,
    footerContent,
    footerContentSection,
    copyright,
    privacyTerms,
    socialMedia,
} from "./index.module.css";

import yowiimg from "../images/yowi-banner-img.svg";
import whoweare from "../images/whoweare.png";
import productsServices from "../images/products_services.png";
import ourReach from "../images/our_reach.png";
import yowiLogo from "../images/yowi-logo.png";
import ypoProduct from "../images/ypoProduct.png";
import yboProduct from "../images/yboProduct.png";
import pfp from "../images/pfp.png";
import ytoProduct from "../images/yto_product.png";
import YBO from "../images/YBO.png";
import YTO from "../images/YTO.png";
import YPO from "../images/YPO.png";
import avatar1 from "../images/notion-avatar1.png";
import avatar2 from "../images/notion-avatar2.png";
import avatar3 from "../images/notion-avatar3.png";
import avatar4 from "../images/notion-avatar4.png";

const IndexPage = () => {
    const date = new Date();
    const dateYear = date.getFullYear().toString();
    return (
        <main>
            <div>
                <img className={logo} src={yowiLogo} />
            </div>

            <div className={banner}>
                <div className={bannerText}>
                    <h1>Giving more for less</h1>
                    <p>
                        YAHSHUA Outsourcing Worldwide Inc. is the services arm
                        of{" "}
                        <a href='https://theabbainitiative.com/'>
                            The ABBA Initiative.
                        </a>{" "}
                        We provide business solutions for every industry.
                    </p>
                </div>
                <div>
                    <img alt='Banner Image' src={yowiimg} />
                </div>
            </div>

            <section className={divider}></section>
            <div className={grid}>
                <section id='aboutUs'>
                    <img alt='Who we are' src={whoweare} />
                    <div className={gridText}>
                        <h2>Who We Are</h2>
                        <p>
                            YAHSHUA Outsourcing Worldwide, Inc. is a
                            professional services and ICT company with over 10
                            years experience in Internal Controls and Management
                            Reporting. Our primary goal is to help businesses
                            attain sustainability and growth with our outsourced
                            system and services in accounting, inventory & tax
                            compliance, and HR management and training.
                        </p>
                    </div>
                </section>
                <section>
                    <div className={gridText}>
                        <h2>Our Products and Services</h2>
                        <p>
                            <a href='https://theabbainitiative.com/'>
                                We now serve as the service arm for The ABBA
                                Initiative.
                            </a>{" "}
                            We provide world class integrated systems leading
                            towards business profitability, stability and
                            growth. We uphold the highest standards of quality
                            and confidentiality.
                        </p>
                        <a href='#products'>
                            Discover our products and services
                        </a>
                    </div>
                    <img alt='Who we are' src={productsServices} />
                </section>
                <section>
                    <img alt='Our Reach' src={ourReach} />
                    <div className={gridText}>
                        <h2>Our Reach</h2>
                        <p>
                            We are a Filipino company with nationwide operation.
                            We’ve partnered with small, medium, and large
                            businesses in different industries. Our vision is
                            bring our solution and make it a way of living for
                            every business all around the world.
                        </p>
                    </div>
                </section>
            </div>
            <div id='products' className={products}>
                <div className={header}>
                    <h1>Our Products and Services</h1>
                </div>
                <div className={dividerWhite}></div>
                <section>
                    <div className={ypo}>
                        <div
                            className={circle}
                            style={{ backgroundColor: "blue" }}></div>
                        <div>
                            <h1 style={{ color: "blue" }}>
                                YAHSHUA Payroll Solutions{" "}
                            </h1>
                            <h3 style={{ color: "blue" }}>
                                “Save Time. Work anywhere. Payroll made easy.”
                            </h3>
                            <p>
                                YAHSHUA Payroll Solution is an end-to-end
                                payroll solution with dedicated modules for
                                payroll records and processes. It includes an
                                integrated timekeeping mobile application and a
                                cloud-based, real time payroll system for admin
                                and employee users.
                            </p>
                            <a href='https://showcase.yahshuapayroll.com/'>
                                Learn More
                            </a>
                        </div>
                        <img src={ypoProduct} />
                    </div>
                    <div className={dividerWhite}></div>
                    <div className={ybo}>
                        <div
                            className={circle}
                            style={{ backgroundColor: "green" }}></div>
                        <div>
                            <h1 style={{ color: "green" }}>
                                YAHSHUA Books Online
                            </h1>
                            <h3 style={{ color: "green" }}>
                                “Making Accounting simplified all in one place.”
                            </h3>
                            <p>
                                YAHSHUA Books Online is designed for accounting
                                records and processes that not only focus on
                                Inventory Management but also includes Sales
                                Processes, Receivable, Billing, and Collection.
                                It also has Purchases Features, Payables and
                                Payment features and financial reporting
                                capabilities, and cash flow management which are
                                very important for decision-making.
                            </p>
                            <a href='https://yahshuabooksonline.com/'>
                                Learn More
                            </a>
                        </div>
                        <img src={yboProduct} />
                    </div>
                    <div className={dividerWhite}></div>
                    <div className={yto}>
                        <div
                            className={circle}
                            style={{ backgroundColor: "teal" }}></div>
                        <div>
                            <h1 style={{ color: "teal" }}>
                                YAHSHUA Tax Online
                            </h1>
                            <h3 style={{ color: "teal" }}>
                                “Tax Express for all business.”
                            </h3>
                            <p>
                                YAHSHUA Tax Online is an accounting, tax and
                                government compliance solution developed with
                                BIR-ready requirements. Generate reports and
                                forms for BIR, as well as for SEC, and more for
                                easier government remittances and submissions.
                            </p>
                            <a href='https://www.yahshuataxonline.com/'>
                                Learn More
                            </a>
                        </div>
                        <img src={ytoProduct} />
                    </div>
                </section>
            </div>
            <div className={dividerWhite}></div>

            <div className={partners}>
                <div className={partnerHeader}>
                    <h1>Join Our Growing Partners Around the Philippines</h1>
                    <p>
                        YAHSHUA Outsourcing has parterned with small, medium,
                        and large enterprises from every industry to provide
                        payroll, accounting and inventory, and tax compliance
                        solutions.
                    </p>
                </div>
                <div className={testimonials}>
                    <div className={testimony}>
                        <img src={avatar3} />
                        <p>
                            “In less than a year of experience, we are doing
                            well in terms of using the system. The system is
                            flexible in configuring our payroll policies. Also,
                            the support team we have right now in Yahshua is
                            24/7 available to all concerns and requests.” - A
                            client from CDO, Philippines
                        </p>
                    </div>
                    <div className={testimony}>
                        <img src={avatar4} />
                        <p>
                            “I am satisfied with the service of YAHSHUA.” -
                            Health Care Service company from Quezon City,
                            Philippines
                        </p>
                    </div>
                    <div className={testimony}>
                        <img src={avatar2} />
                        <p>
                            “The YPO is a friendly app to use, so easy to
                            understand, thus making our payroll job promptly
                            done. Further, the YAHSHUA would act immediately on
                            any favour that we would ask in times where there
                            are errors or if we would ask for anything to
                            customize.” - A client from Dipolog City,
                            Philippines
                        </p>
                    </div>
                    <div className={testimony}>
                        <img src={avatar1} />
                        <p>
                            “YAHSHUA has been consistent with their customer
                            service support. Whenever I need them, they’re
                            always there.” - A client from Pasig City,
                            Philippines
                        </p>
                    </div>
                </div>
                <div className={products}>
                    <div className={partnerHeader}>
                        <h1>Do More For Less with YAHSHUA</h1>
                        <p>
                            Whether it’s payroll, back-end accounting, tax and
                            compliance, YAHSHUA can do all that <b>and more.</b>
                        </p>
                    </div>
                    <div className={productLineUp}>
                        <a href='https://showcase.yahshuapayroll.com/'>
                            <img src={YPO} />
                        </a>
                        <a href='https://yahshuabooksonline.com/'>
                            <img src={YBO} />
                        </a>
                        <a href='https://www.yahshuataxonline.com/'>
                            <img src={YTO} />
                        </a>
                    </div>
                </div>
                <div className={dividerWhite}></div>
            </div>
            <div className={products}>
                <div className={header}>
                    <h1>Contact Us Today</h1>
                </div>
                <section>
                    <div className={ypo}>
                        <div
                            className={circle}
                            style={{ backgroundColor: "blue" }}></div>
                        <div>
                            <h1>
                                Visit us at{" "}
                                <a href='https://theabbainitiative.com/'>
                                    theabbainitiative.com
                                </a>{" "}
                                to learn more{" "}
                            </h1>
                        </div>
                    </div>
                </section>
            </div>
            <div className={dividerWhite}></div>
            <footer>
                <div className={footerContent}>
                    <div className={footerContentSection}>
                        <ul aria-label='Solutions'>
                            <a href='https://showcase.yahshuapayroll.com/'>
                                <li>YAHSHUA Payroll Solutions</li>
                            </a>
                            <a href='https://yahshuabooksonline.com/'>
                                <li>YAHSHUA Books Online</li>
                            </a>
                            <a href='https://www.yahshuataxonline.com/'>
                                <li>YAHSHUA Tax Online</li>
                            </a>
                        </ul>
                    </div>
                    <div className={footerContentSection}>
                        <ul aria-label='Company'>
                            <a href='#aboutUs'>
                                <li>About Us</li>
                            </a>
                            <a href='https://www.facebook.com/yahshuaoutsourcingworldwide.inc'>
                                <li>Contact Us</li>
                            </a>
                            {/* <li>Terms & Privacy</li> */}
                        </ul>
                    </div>
                </div>
                <div className={dividerWhiteMini}></div>
                <div className={socialMedia}>
                    <ul>
                        <li>
                            <a href='https://www.facebook.com/yahshuaoutsourcingworldwide.inc'>
                                <img src='https://img.icons8.com/ios-glyphs/40/000000/facebook-circled--v1.png' />
                            </a>
                        </li>
                        {/* <li>
                            <a href='#'>
                                <img src='https://img.icons8.com/ios-glyphs/40/000000/instagram-new.png' />
                            </a>
                        </li> */}
                        <li>
                            <a href='https://www.linkedin.com/company/yahshua-outsourcing-worldwide-inc/'>
                                <img src='https://img.icons8.com/ios-glyphs/40/000000/linkedin-2--v1.png' />
                            </a>
                        </li>
                        {/* <li>
                            <a href='https://www.youtube.com/channel/UC-3AcnpT_3NUnKdhm-ASMJA'>
                                <img src='https://img.icons8.com/ios-glyphs/40/000000/youtube-play.png' />
                            </a>
                        </li> */}
                    </ul>
                </div>
                <div className={dividerWhiteMini}></div>
                <div className={copyright}>
                    © YAHSHUA Outsourcing Worldwide, Inc. {dateYear}
                </div>
                {/* <div className={privacyTerms}>
                    <a href='#'>
                        <div className={copyright}>Privacy Policy</div>
                    </a>
                    <a href='#'>
                        <div className={copyright}>Terms of Use</div>
                    </a>
                </div> */}
            </footer>
            <div className={dividerWhiteMini}></div>
        </main>
    );
};

export default IndexPage;

export const Head = () => <title>Home Page</title>;
